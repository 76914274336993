import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'

import theme from './src/components/app/theme'

const onRedirectCallback = (appState) => {
  let route = ''

  if (appState?.returnTo.includes('/app/profile')) {
    route = '/app/profile'
  }

  if (appState?.returnTo.includes('/app/forms')) {
    route = appState?.returnTo
  }

  window.location.href = `/app/auth0-redirect?route=${route}`
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: 'openid profile admin',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <ChakraProvider theme={theme}>{element}</ChakraProvider>
    </Auth0Provider>
  )
}
