import { extendTheme } from '@chakra-ui/react'
import 'react-material-symbols/rounded'

const theme = extendTheme({
  fonts: {
    heading: `'Folio Std Light', sans-serif`,
    body: `'Source Code Pro', monospace`,
  },
  colors: {
    brand: {
      100: 'var(--color-primary)',
      200: '#000',
    },
    primary: {
      light: 'var(--color-primary-light)',
      default: 'var(--color-primary)',
      dark: 'var(--color-primary-dark)',
    },
    secondary: {
      light: '#666',
      default: '#333',
      dark: '#111',
    },
    error: {
      default: '#FF0000',
    },
    button: {
      primary: '#2C2F2A',
      secondary: '#fff',
    },
    border: {
      default: '#D7DEDD',
      primary: '#000',
      secondary: '#A3A9A8',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'heading',
        pt: '5px',

        _disabled: {
          cursor: 'not-allowed',
        },
      },
      variants: {
        primary: {
          bg: 'button.primary',
          color: 'button.secondary',
          borderRadius: '3px',
          border: (theme) => `1px solid ${theme.colors.border.primary}`,
          _hover: {
            color: 'var(--color-primary)',
          },
        },
        secondary: {
          bg: 'button.secondary',
          color: 'button.primary',
          borderRadius: '3px',
          border: (theme) => `1px solid ${theme.colors.border.primary}`,
          _hover: {
            bg: 'var(--color-primary)',
          },
        },
        link: {
          color: 'button.primary',
          textDecoration: 'underline',
          _hover: {
            color: 'border.primary',
          },
        },
      },
    },
    Input: {
      defaultProps: {
        size: 'lg',
      },
      variants: {
        outline: {
          field: {
            outline: 'none',
            borderRadius: '0',
            border: 'none',
            borderBottom: (theme) => `1px solid ${theme.colors.border.default}`,
            _hover: {
              borderColor: 'border.secondary',
            },
            _active: {
              borderColor: 'border.primary',
            },
            _focusVisible: {
              boxShadow: 'none',
              borderColor: 'border.primary',
            },
            _invalid: {
              boxShadow: 'none',
            },
          },
        },
      },
    },
    Textarea: {
      baseStyle: {},
      defaultProps: {
        size: 'lg',
      },
      variants: {
        outline: {
          outline: 'none',
          borderRadius: '0',
          borderColor: 'border.default',
          _hover: {
            borderColor: 'border.secondary',
          },
          _active: {
            borderColor: 'border.primary',
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'border.primary',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        color: 'secondary.dark',
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: 'heading',
      },
    },
    Modal: {
      baseStyle: {
        header: {
          fontFamily: 'heading',
          fontSize: '30px',
          pt: 0,
        },
      },
    },
  },
})

export default theme
